"use client";

import React from "react";
import FormField, { Error } from "../form-field/FormField";
import classNames from "classnames";

type Errors = { [name: string]: Error };

export type InputChangeHandler = React.FormEventHandler<HTMLInputElement>;

export interface InputProps extends React.ComponentPropsWithoutRef<"input"> {
  name: string; // Override the default name prop to make ours required
  value?: string; // Hijack the value prop to keep our input an uncontrolled component
  label?: string;
  hint?: string;
  paired?: "left" | "right";
  helpText?: string;
  helpLabel?: string;
  errors?: Errors;
  mask?: any;
  guide?: boolean;
}

export type InputRef = HTMLInputElement;

const Input = React.forwardRef<InputRef, InputProps>((props, ref) => {
  const {
    name,
    hint,
    label,
    value,
    paired,
    helpText,
    helpLabel,
    defaultValue,
    type = "text",
    disabled = false,
    errors = {},
    mask,
    guide,
    ...inputProps
  } = props;

  const inputClasses = classNames(
    "form-input",
    "border border-gray-300",
    "bg-white",
    "mb-2 p-3",
    "w-full",
    "focus:ring-teal-100 focus:border-teal-300",
    "disabled:cursor-not-allowed",
    "placeholder-gray-400",
    {
      rounded: !paired,
      "rounded-l": paired === "left",
      "rounded-r": paired === "right",
      "border-theme-color-error": errors && errors[name],
      "text-gray-400": disabled,
    }
  );

  return (
    <FormField
      id={`${name}-label`}
      hint={hint}
      label={label}
      htmlFor={name}
      error={errors[name]}
      helpText={helpText}
      helpLabel={helpLabel}
    >
      <input
        {...inputProps}
        id={name}
        ref={ref}
        key={name}
        name={name}
        type={type}
        disabled={disabled}
        className={inputClasses}
        defaultValue={defaultValue}
        value={value}
        aria-invalid={!!errors[name]}
        aria-errormessage={`${name}-error`}
      />
    </FormField>
  );
});

Input.displayName = "Input";

export default Input;
