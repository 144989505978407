"use client";

import * as React from "react";
import * as yup from "yup";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { signIn } from "#src/utils/aws-amplify-auth";
import Alert from "#src/app/(app)/_components/alert/Alert";
import Input from "#src/app/(app)/_components/input/Input";
import Button from "#src/app/(app)/_components/button/Button";
import { useAuthState } from "../../../AuthContext";
import PasswordInput from "../../../_components/password-input/PasswordInput";

type Errors = {
  [name: string]: { message: string };
};

type SignInFormValues = yup.InferType<typeof signInFormSchema>;

const signInFormSchema = yup.object({
  username: yup
    .string()
    .nullable()
    .email("The email is invalid")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});

export default function SignInForm() {
  const router = useRouter();
  const { setAuthState } = useAuthState();

  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<Errors>({});

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setErrors({});
    setLoading(true);

    const formData = new FormData(event.currentTarget);

    const values: SignInFormValues = {
      username: formData.get("username").toString(),
      password: formData.get("password").toString(),
    };

    try {
      await signInFormSchema.validate(values, { abortEarly: false });

      const { isSignedIn, nextStep } = await signIn(values);

      if (isSignedIn) {
        router.push("/");
      }

      if (nextStep?.signInStep === "CONFIRM_SIGN_IN_WITH_SMS_CODE") {
        setAuthState({ isSignedIn, nextStep, ...values });
      }
    } catch (error) {
      console.error(error);

      setLoading(false);

      if (error instanceof yup.ValidationError) {
        const formErrors: Errors = {};

        error.inner.forEach((error) => {
          if (error.path) {
            formErrors[error.path] = { message: error.message };
          }
        });

        setErrors(formErrors);
      } else {
        setErrors({
          signIn: { message: error.message },
        });
      }
    }
  };

  return (
    <form
      className="flex flex-col justify-center space-y-6"
      onSubmit={handleSubmit}
    >
      <h1 className="block w-full text-2xl text-center text-gray-800">
        Sign in to your account
      </h1>

      {errors?.signIn && (
        <Alert text={errors.signIn.message} status={Alert.Status.CRITICAL} />
      )}

      <Input
        type="text"
        name="username"
        label="Email address"
        placeholder="Enter your email"
        errors={errors}
      />

      <PasswordInput errors={errors} />

      <Link
        href="/reset-password"
        className="mb-16 text-sm text-green-700 cursor-pointer select-none"
      >
        Forgot your password?
      </Link>

      <Button size={Button.Size.LARGE} loading={loading} disabled={loading}>
        Sign in
      </Button>
    </form>
  );
}
