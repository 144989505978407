import * as React from "react";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import Input from "#src/app/(app)/_components/input/Input";

type Errors = {
  [name: string]: { message: string };
};

export default function PasswordInput({
  errors,
  hint,
}: {
  errors: Errors;
  hint?: string;
}) {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <div className="relative">
      <Input
        label="Password"
        name="password"
        type={showPassword ? "text" : "password"}
        placeholder="Enter your password"
        errors={errors}
        hint={hint}
      />

      {showPassword ? (
        <EyeOffIcon
          data-testid="hide-password"
          className="absolute w-4 h-4 ml-2 text-gray-600 cursor-pointer top-12 right-4 hover:text-gray-400"
          onClick={() => setShowPassword(!showPassword)}
        />
      ) : (
        <EyeIcon
          data-testid="show-password"
          className="absolute w-4 h-4 ml-2 text-gray-600 cursor-pointer top-12 right-4 hover:text-gray-400"
          onClick={() => setShowPassword(!showPassword)}
        />
      )}
    </div>
  );
}
