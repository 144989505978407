"use client";

import { useAuthState } from "../AuthContext";
import SignInForm from "./_components/sign-in/SignInForm";
import Authenticate from "./_components/authenticate/Authenticate";

export default function LoginPage() {
  const { authState } = useAuthState();

  if (authState) {
    return <Authenticate />;
  }

  return <SignInForm />;
}
