import * as React from "react";
import classNames from "classnames";
import LoadingSpinner from "./LoadingSpinner";
import Link from "next/link";

export const Size = {
  SMALL: "sm",
  MEDIUM: "md",
  LARGE: "lg",
} as const;

export const Emphasis = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  TRANSPARENT: "transparent",
  ERROR: "error",
} as const;

type StaticProperties = {
  Size: typeof Size;
  Emphasis: typeof Emphasis;
};

type Values<T> = T[keyof T];

export interface ButtonProps
  extends React.HTMLAttributes<HTMLButtonElement | HTMLAnchorElement> {
  children?: React.ReactNode | React.ReactNode[];
  size?: Values<typeof Size>;
  emphasis?: Values<typeof Emphasis>;
  inverted?: boolean;
  loading?: boolean;
  disabled?: boolean;
  href?: string;
  query?: Record<string, string>;
  type?: "button" | "submit" | "reset";
}

const Button: React.FunctionComponent<ButtonProps> & StaticProperties = ({
  href,
  children,
  inverted,
  loading,
  size = Size.MEDIUM,
  emphasis = Emphasis.PRIMARY,
  query,
  type,
  ...props
}) => {
  const { className, ...otherProps } = props;

  const baseClasses = [
    "inline-flex items-center justify-center gap-2",
    "border border-transparent",
    "ring-theme-button-primary",
    "text-center leading-snug",
    "rounded-theme-radius",
    "transition-all duration-300",
    "hover:cursor-pointer",
    "disabled:cursor-not-allowed",
  ]
    .join(" ")
    .trim();

  const buttonClasses = classNames(
    baseClasses,
    [
      emphasis === "error"
        ? `hover:text-theme-color-${emphasis}-hover`
        : "hover:shadow-md focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-transparent",
    ],
    [
      emphasis === "error"
        ? `text-theme-color-${emphasis}`
        : inverted
          ? `border-theme-button-${emphasis} text-theme-color-on-background`
          : `bg-theme-button-${emphasis} text-theme-color-on-${emphasis} hover:bg-theme-button-${emphasis}-hover`,
    ],
    [props.disabled ? "opacity-50" : ""],
    {
      "py-1 px-4": size === "sm",
      "py-2 px-4": size === "md",
      "py-3 px-8": size === "lg",
    },
    className
  );

  if (href && !props.disabled) {
    return (
      <Link
        {...props}
        href={{ pathname: href, query }}
        className={buttonClasses}
      >
        {children}
      </Link>
    );
  }

  return (
    <button type={type} className={buttonClasses} {...otherProps}>
      {loading && <LoadingSpinner />}
      {children}
    </button>
  );
};

Button.Size = Size;
Button.Emphasis = Emphasis;

export default Button;
