"use client";

import * as React from "react";
import * as yup from "yup";
import { useRouter } from "next/navigation";
import { confirmSignIn, signIn } from "#src/utils/aws-amplify-auth";
import Alert from "#src/app/(app)/_components/alert/Alert";
import Input from "#src/app/(app)/_components/input/Input";
import Button from "#src/app/(app)/_components/button/Button";
import { useAuthState } from "../../../AuthContext";

import type { ConfirmSignInWithSMSCode } from "@aws-amplify/auth/dist/esm/types/models";

type Errors = {
  [name: string]: { message: string };
};

type AuthenticateFormValues = yup.InferType<typeof authenticateFormSchema>;

type AlertState = {
  alert: boolean;
  alertMessage: string;
  alertStatus: (typeof Alert.Status)[keyof typeof Alert.Status];
};

const authenticateFormSchema = yup.object({
  code: yup.string().required("Authentication code is required"),
});

export default function AuthenticateForm() {
  const router = useRouter();
  const { authState } = useAuthState();

  const phoneNumber =
    (authState?.nextStep as ConfirmSignInWithSMSCode)?.codeDeliveryDetails
      ?.destination ?? "";

  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<Errors>({});
  const [alertState, setAlertState] = React.useState<AlertState>({
    alert: true,
    alertMessage: `A code has been sent to your phone number ending in ${phoneNumber}`,
    alertStatus: Alert.Status.POSITIVE,
  });

  async function resendConfirmationCode() {
    setLoading(true);

    setAlertState({
      alert: true,
      alertMessage: `A new code has been sent to your phone number ending in ${phoneNumber}`,
      alertStatus: Alert.Status.PRIMARY,
    });

    try {
      await signIn({
        username: authState.username,
        password: authState.password,
      });

      setLoading(false);
    } catch (err) {
      setAlertState({
        alert: true,
        alertMessage: err.message,
        alertStatus: Alert.Status.CRITICAL,
      });

      setLoading(false);
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setErrors({});
    setLoading(true);

    const formData = new FormData(event.currentTarget);
    const values: AuthenticateFormValues = {
      code: formData.get("code").toString(),
    };

    try {
      await authenticateFormSchema.validate(values, { abortEarly: false });

      const { isSignedIn } = await confirmSignIn({
        challengeResponse: values.code,
      });

      if (isSignedIn) {
        router.push("/");
      }
    } catch (error) {
      console.error(error);

      setLoading(false);

      if (error instanceof yup.ValidationError) {
        const formErrors: Errors = {};

        error.inner.forEach((error) => {
          if (error.path) {
            formErrors[error.path] = { message: error.message };
          }
        });

        setErrors(formErrors);
      } else {
        let message = error.message;

        if (error.message.includes("session is expired")) {
          message = message + " Please try logging in again.";
        }

        setAlertState({
          alert: true,
          alertMessage: message,
          alertStatus: Alert.Status.CRITICAL,
        });
      }
    }
  };

  return (
    <form
      className="flex flex-col justify-center space-y-6"
      onSubmit={handleSubmit}
    >
      <h1 className="block w-full text-2xl text-center text-gray-800">
        Enter authentication code
      </h1>

      {alertState.alert && (
        <Alert status={alertState.alertStatus} text={alertState.alertMessage} />
      )}

      <Input
        type="text"
        name="code"
        label="Authentication code"
        placeholder="Enter your code"
        errors={errors}
      />

      <span
        onClick={resendConfirmationCode}
        className="mb-16 text-sm text-green-700 cursor-pointer select-none"
      >
        Resend verification code
      </span>

      <Button size={Button.Size.LARGE} loading={loading} disabled={loading}>
        Sign in
      </Button>
    </form>
  );
}
